import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

export const HomeSection = {
  ABOUT: "about",
  NATWEST: "natwest",
  EQUINE_REGISTER: "equineRegister",
  KFC: "kfc",
  CONTACT: "contact",
} as const;

type HomeSectionType = typeof HomeSection[keyof typeof HomeSection];

export interface AppState {
  onBoarded: boolean;
  homeSection: HomeSectionType;
}

const initialState: AppState = {
  onBoarded: false,
  homeSection: HomeSection.ABOUT,
};

export const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setOnBoarded: (state, action: PayloadAction<boolean>) => {
      state.onBoarded = action.payload;
    },
    setHomeSection: (state, action: PayloadAction<HomeSectionType>) => {
      state.homeSection = action.payload;
    },
  },
});

export const { setOnBoarded, setHomeSection } = appSlice.actions;

export default appSlice.reducer;
