import {
  configureStore,
  // applyMiddleware,
  combineReducers,
} from "@reduxjs/toolkit";

import userReducer from "./features/user/userSlice";
import appReducer from "./features/app/appSlice";

import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import storage from "redux-persist/lib/storage";
// import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
// import { StoreTypes } from '../types';

// import AsyncStorage from '@react-native-community/async-storage';
const reducers = combineReducers({
  user: userReducer,
  app: appReducer,
});

const persistConfig = {
  timeout: 10000,
  key: "data",
  storage,
};

const persistedReducer = persistReducer(persistConfig, reducers);

const STORE = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

// const persistedReducer = persistReducer(persistConfig, reducers);
// const middlewares: any[] = [];

// const composeEnhancers = composeWithDevTools({});
// const STORE: StoreTypes.Store = createStore(
//   persistedReducer,
//   composeEnhancers(applyMiddleware(...middlewares)),
// );
// const persistor = persistStore(STORE);

// const storeHelpers = {
// getIsLoggedIn: () => STORE.getState().user.isLoggedIn,
// };

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof STORE.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof STORE.dispatch;

export const persistor = persistStore(STORE);
export { STORE };
