import { Home } from "./Home";
import { Natwest } from "./Natwest";
import { EquineRegister } from "./EquineRegister";
import { KFC } from "./KFC";
import { Footer } from "./Footer";

export const Tiles = {
  Home,
  Natwest,
  EquineRegister,
  KFC,
  Footer,
};
