import { useNavigate } from "react-router-dom";
import styles from "./About.module.scss";

export const AboutPage = () => {
  const navigate = useNavigate();
  return (
    <div className={styles.container}>
      <button onClick={() => navigate(-1)}>About</button>
      <div>
        About is an ecosystem backdrop opf all logos and frameworks, ie adobe,
        photoshop, javasript typescript react, swift, c4d...
        <br />
        and the text half way across as per homepage...
      </div>
    </div>
  );
};
