import React from "react";
import background from "../../assets/images/bgs/home.jpg";
import styles from "./Tile.module.scss";

export const Home = ({
  backgroundColor = "#000",
  textColor = "#FFF",
}: {
  backgroundColor?: string;
  textColor?: string;
}) => {
  return (
    <div
      className={styles.tileContainer}
      style={{
        backgroundColor,
        color: textColor,
        backgroundImage: `url(${background})`,
      }}
    >
      <div
        style={{
          zIndex: 2,
          position: "absolute",
          padding: "40px",
          bottom: "15%",
        }}
      >
        <h1>Senior Developer</h1>

        <p style={{ maxWidth: 600, paddingTop: 30 }}>
          TypeScript/JavaScript developer using technology & design to improve
          business processes. Extensive freelance career working with a bluechip
          portfolio of diverse projects from design to deployment across
          multiple platforms.
        </p>
      </div>
      <div
        style={{
          backgroundColor: "#000000aa",
          opacity: 0.7,
          position: "absolute",
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          zIndex: 1,
        }}
      />
    </div>
  );
};
