import { useEffect, useRef } from "react";
import { useSelector } from "react-redux";

import { Tiles } from "../components/tiles";
import styles from "./HomeCSS.module.scss";
import { RootState } from "../store";
import { setHomeSection, HomeSection } from "../store/features/app/appSlice";

export const HomeCSSPage = () => {
  const homeSection = useSelector((store: RootState) => store.app.homeSection);
  const natwestRef = useRef<HTMLDivElement>(null);
  const kfcRef = useRef<HTMLDivElement>(null);
  const equineRef = useRef<HTMLDivElement>(null);
  // useEffect(() => {
  //   switch (homeSection) {
  //     case HomeSection.NATWEST:
  //       natwestRef.current?.scrollIntoView({ behavior: "auto" });
  //       break;
  //     case HomeSection.KFC:
  //       kfcRef.current?.scrollIntoView({ behavior: "auto" });
  //       break;
  //     case HomeSection.EQUINE_REGISTER:
  //       equineRef.current?.scrollIntoView({ behavior: "auto" });
  //       break;
  //     default:
  //   }
  //   return () => {
  //     console.log("unmount");
  //   };
  // }, [homeSection]);

  return (
    <div className={styles.container}>
      <section>
        <Tiles.Home />
      </section>
      {/* <section ref={natwestRef}>
        <Tiles.Natwest />
      </section>
      <section ref={equineRef}>
        <Tiles.EquineRegister />
      </section>
      <section ref={kfcRef}>
        <Tiles.KFC />
      </section> */}
      {/* <section>
        <Tiles.Footer />
      </section> */}
    </div>
  );
};
