import { useRef, useEffect, FunctionComponent, ReactNode } from "react";
import { Location } from "react-router-dom";
import { NavigationType } from "react-router-dom";
import { gsap, Power1 } from "gsap";

import styles from "./AppTransition.module.scss";

type AppTransitionProps = {
  children: ReactNode;
  location: Location;
  navigationType: NavigationType;
};

const duration = 0.5;
const timeOffset = "-=0.5";

const getNavigationType = (navigationType: string) =>
  navigationType === "POP" ? "pop" : "push";

export const AppTransition: FunctionComponent<AppTransitionProps> = ({
  children,
  location,
  navigationType,
}) => {
  const tl = useRef<GSAPTimeline>();
  const previousChild = useRef<ReactNode>(null);
  const previousChildLocation = useRef<string>("");
  const firstChild = useRef<HTMLDivElement>(null);
  const secondChild = useRef<HTMLDivElement>(null);
  const thirdChild = useRef<HTMLDivElement>(null);
  const firstTime = useRef<boolean>(false);

  // const addTimeline = () =>
  //   getNavigationType(navigationType) === "pop"
  //     ? getTimelinePop()
  //     : getTimelinePush();

  useEffect(() => {
    if (!tl.current) {
      tl.current = gsap.timeline({
        paused: true,
      });
    }
    previousChild.current = children;
    previousChildLocation.current = location.pathname;
    // console.log(getNavigationType());
    // console.log(getNavigationType());

    if (firstTime.current) {
      tl.current?.add(
        getNavigationType(navigationType) === "pop"
          ? getTimelinePop()
          : getTimelinePush()
      );
      tl.current?.resume().then(() => {
        // gsap.set(secondChild.current, { zIndex: 2 });
        // gsap.set(firstChild.current, { zIndex: 1 });
        console.log("completed");
      });
    } else {
      firstTime.current = true;
    }
  }, [children, location, navigationType]);

  const getTimelinePush = () => {
    gsap.set(secondChild.current, { x: 430 });
    gsap.set(firstChild.current, { x: 0 });

    return gsap
      .timeline()
      .to(secondChild.current, { x: 0, duration }, timeOffset)
      .to(firstChild.current, { x: -100, duration }, timeOffset);
  };

  const getTimelinePop = () => {
    gsap.set(secondChild.current, { x: -430, zIndex: 1 });
    gsap.set(firstChild.current, { x: 0, zIndex: 2 });
    return gsap.timeline();
    // .to(secondChild.current, { x: 0, duration }, timeOffset)
    // .to(firstChild.current, { x: 0, duration }, timeOffset);
  };

  // const getClassForEnter = () =>
  //   styles[`pageTransitionEnter-${getDirection()}`];

  // const getClassForExiting = () =>
  //   styles[`pageTransitionExitActive-${getDirection()}`];

  return (
    <div style={{ position: "absolute", left: 0, top: 0 }}>
      <div ref={firstChild} className={styles.firstChild}>
        {previousChild.current}
      </div>
      <div ref={secondChild} className={styles.secondChild}>
        {children}
      </div>
      <div ref={thirdChild} className={styles.thirdChild}>
        {children}
      </div>
    </div>
  );
  // <TransitionGroup>
  //   <CSSTransition
  //     key={location.pathname}
  //     timeout={timeout}
  //     unmountOnExited={true}
  //     // className={styles}
  //     // style={{ zIndex: zed }}
  //     onEnter={(node: HTMLElement): void => {
  //       node && node.classList.add(getClassForEnter());
  //       // if (getDirection() === "right") {
  //       //   node.style.zIndex = "3";
  //       // }
  //     }}
  //     onEntering={(node: HTMLElement): void => {
  //       node && node.classList.add(styles.pageTransitionEnterActive);
  //     }}
  //     onEntered={(node: HTMLElement): void => {
  //       node && node.classList.remove(getClassForEnter());
  //       node && node.classList.remove(styles.pageTransitionEnterActive);
  //       // node.style.zIndex = "2";
  //     }}
  //     onExiting={(node: HTMLElement): void => {
  //       node && node.classList.add(getClassForExiting());
  //     }}
  //   >
  //     {children}
  //   </CSSTransition>
  // </TransitionGroup>
};
