// import { useRef } from "react";
// import {
//   // useScroll,
//   useDrag,
// } from "@use-gesture/react";
// import { useSprings, animated } from "@react-spring/web";
import {
  Parallax,
  ParallaxLayer,
  //  IParallax
} from "@react-spring/parallax";
// import clamp from "lodash.clamp";

// import { Tiles } from "../components/tiles";

export const HomeSpringPage = () => {
  // const parallax = useRef<IParallax>(null!);

  return (
    // <div style={{ overflow: "scroll", height: "100vh" }}>
    <Parallax pages={3} style={{ backgroundColor: "pink" }}>
      <ParallaxLayer
        offset={0}
        speed={2.5}
        style={{ backgroundColor: "orange", height: "100vh" }}
      >
        <p>Parallax</p>
      </ParallaxLayer>
      <ParallaxLayer offset={1} speed={1} style={{ backgroundColor: "green" }}>
        <p>Parallax 2</p>
      </ParallaxLayer>
      <ParallaxLayer
        offset={2}
        speed={2.5}
        style={{ backgroundColor: "yellow" }}
      >
        <p>Parallax 3</p>
      </ParallaxLayer>
    </Parallax>
    // </div>
  );
};
