import "./App.css";
import {
  BrowserRouter,
  Route,
  Routes,
  useLocation,
  NavigationType,
  useNavigationType,
} from "react-router-dom";

import { AppTransition } from "./utils/AppTransition";
import { Navigation } from "./components/Navigation";
import {
  // HoldingPage,
  AboutPage,
  HomeCSSPage,
} from "./pages";
// import { isHoldingPage } from "./constants";

const AppRoutes = () => {
  const location = useLocation();
  const navigationType: NavigationType = useNavigationType();

  return (
    <AppTransition location={location} navigationType={navigationType}>
      <Routes location={location}>
        <Route path="/about" element={<AboutPage />} />
        <Route path="/" element={<HomeCSSPage />} />
      </Routes>
    </AppTransition>
  );
};

function App() {
  return (
    <BrowserRouter>
      <AppRoutes />
    </BrowserRouter>
  );
}

export default App;
