import { useRef } from "react";
import {
  // useScroll,
  useDrag,
} from "@use-gesture/react";
import { useSprings, animated } from "@react-spring/web";
import clamp from "lodash.clamp";

import { Tiles } from "../components/tiles";

const TOP_MARGIN = 160;
const CARD_TOP_MARGIN = 16;
const WINDOW_HEIGHT = window.innerHeight;

export const HomePage = () => {
  const sections = [1, 2, 3];

  const height = window.innerHeight;
  const index = useRef(4); //DEFAULT TO MONEY SECTION

  const getY = (diff: number, i: number) => {
    if (i === 0 && index.current === 0) {
      return 100;
    } else if (i === 0 && index.current > 0) {
      return WINDOW_HEIGHT;
    } else if (i <= index.current) {
      return TOP_MARGIN - diff * 16;
    }
    if (i > index.current) {
      const bottomOffset = index.current > 0 ? 0 : 20;
      const offset = (sections.length - i) * 40 - bottomOffset;
      const value = height - offset - CARD_TOP_MARGIN;
      return value;
    }
    return (i - index.current) * height - 40 - CARD_TOP_MARGIN;
  };

  const getValues = (i: number) => {
    const diff = index.current - Math.abs(i);
    const opacity = diff > 0 ? 1 - (diff - 1) * 0.51 : 1;
    const y = getY(diff, i);
    return { y, opacity };
  };

  const [props, setProps] = useSprings(sections.length, (i) => getValues(i));

  const animate = () => setProps.start(getValues);

  const bind = useDrag((state: any) => {
    console.log(state);

    const {
      active,
      movement: [, my],
      direction: [, yDir],
      // cancel,
      offset: [, yOffset],
    } = state;

    console.log(my);
    state.scrolling
      ? console.log("I'm being scrolled")
      : console.log("I'm not being scrolled");

    // console.log(state);
    // console.log(Math.abs(my));
    // if (active && Math.abs(my)) {
    if (active && Math.abs(my) > 100) {
      index.current = clamp(
        index.current + (yDir > 0 ? -1 : 1),
        0,
        sections.length - 1
      );
      // cancel();
    }
    animate();
  });

  return (
    <div
      style={{ overflow: "scroll", height: "100vh", backgroundColor: "pink" }}
      {...bind()}
    >
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <Tiles.Home />
      <Tiles.Natwest />
      <Tiles.EquineRegister />
    </div>
  );
};
